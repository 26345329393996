footer {
    margin-top: 25px;
    background-color: $navy;
    color: $menu-item;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        text-align: center;
    }
}