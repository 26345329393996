header {
    background-color: $navy;
    color: $menu-item;
    margin-bottom: 25px;
    .top-menu {
        text-align: right;
        text-transform: uppercase;
        padding-top: 6px;
        a {
            padding: 10px;
            transition: all .5s;
            color: $white;
        }

        @include states {
            cursor: pointer;
            a {
                
                text-decoration: none;
            }

        }
    }

    svg#logo {
        max-height: 30px;
        height: 30px;
        fill: white;
        @include desktop {
            margin-top: 20px;
        }
    }
    

    .header-wrapper {
        background: url('/img/header-background-svg.svg') no-repeat center;
        background-size: cover;
        padding: 30px 0 60px;
        h1 {
            @include laptop{
                margin-top: 50px;
            }
        }
    }

    .video-link {
        height: 200px;
        background: url('/img/header-devices.png') no-repeat center;
        background-size: 500px;
        margin-top: 50px;

        @include at(992){
            height: 390px;
            background-size: 660px;
            margin-top: 120px;
        }

        @include at(1200){
            height: 400px;
            background-size: 790px;
            margin-top: 60px;
        }
    }
}