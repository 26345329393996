// Contains 
@import 'toolbox/variables';

@import 'toolbox/mixins';
@import 'toolbox/styleguide';

// font colors
@import 'brand/palette';
// font families, sizes, line-heights
@import 'brand/typography';

@import 'stage/scrollify';

@import 'header';
@import 'footer';

@import 'pricing-plans';
@import 'features';
@import 'benefits';
@import 'components/hbspt-form';

img {
    max-width: 100%;
}

#player {
    display: none;
    &.playing {
        display: block;
    }
}

.device-photo{
    height: 500px;
    background: url('/img/ipad-mockup.png') no-repeat center center;
    background-size: 1040px;
    margin: 0 -15px;
    @include laptop {
        background-size: 950px;
    }
}

.check-list {
    .item {
        font-size: 16px;
        font-style: italic;
        font-weight: 600;
        line-height: 3.6rem;
        display: flex;
        align-items: center;
        @include phone {
            padding: 10px 0;
        }
        i {
            color: $algae;
            margin-right: 30px;
            font-size: 30px;
        }
    }
}

.light-wrapper {
    background-color: $light-grey;
    margin: 50px 0;
    padding: 50px 0;
}

.icon {
    max-width: 60px;
}

button.notapill {
    border-radius: 50px;
    background-color: $algae;
    color: $white;
    border-color: $light-algae;
    border-style: solid;
    padding: 10px 40px;
    box-shadow: 0 3px 6px rgba(0,0,0,.16);
    @include theme('header') {
        margin-top: 40px;
        @include laptop {
            margin-top: 0;
        }
    }

    @include theme('.pricing-plan-desktop .pricing-row'){
        font-size: 16px;
        padding: 10px 20px;
        border-radius: 15px;
    }

    @include theme('.featured'){
        background-color: $white;
        color: $algae;
        border-color: $light-algae;
    }
}

.pricing-plan-header {
    text-align: center;
}