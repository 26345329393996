.scroll-panel {
    .goodies {
        padding: 90px 40px;
    }
}
.scroll-pager {
    list-style: none;
    position:fixed;
    right:20px;
    top: 50%;
    transform: translateY(-50%);
    font-size:1.4em;
    z-index: 10;
    a {
        display:block;
        height:20px;
        margin-bottom:5px;
        color:black;
        position:relative;
        padding:4px;
        &:after {
            transition:box-shadow 0.5s ease;
            width:10px;
            height:10px;
            display: block;
            border:1px solid;
            border-radius:50%;
            content:'';
            position: absolute;
            margin:auto;
            top:0;
            right:4px;
            bottom:0;
        }
        &.hover-text {
            position:absolute;
            right:15px;
            top:7px;
            opacity:0;
            transition: opacity 0.5s ease;
            padding-right: 15px;
        }
        &.active {
            &:after {
                box-shadow:inset 0 0 0 5px;
            }
        }
        &:hover {
            .hover-text {
                opacity: 1;
            }
        }
    }
}
