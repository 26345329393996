@import url('https://fonts.googleapis.com/css?family=Open+Sans|Source+Sans+Pro');

/*--------------------------------------FONT-FAMILIES--------*/
$ssans: 'Source Sans Pro', sans-serif;
$osans: 'Open Sans', sans-serif;

/*--------------------------------------FONT-WEIGHTS--------*/
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;

/*--------------------------------------COLORS--------*/
$blue: #02456B;
$navy: #00344d;
$slate: #2e2e2e;
$yellow: #ffb407;
$lightgray: #f5f5f5;
$brightblue: #0086c4;
$paleblue: #a3bcd6;
$white: #fff;
$black: #000;