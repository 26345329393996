/*-------MEDIA QUERIES-------*/

@mixin phone() {
    @media only screen and (min-width: 425px) {
        @content;
    }
}
@mixin tablet() {
    @media only screen and (min-width: 768px) {
        @content;
    }
}
@mixin laptop() {
    @media only screen and (min-width: 992px) {
        @content;
    }
}
@mixin desktop() {
    @media only screen and (min-width: 1440px) {
        @content;
    }
}
@mixin at($size) {
    @media only screen and (min-width: $size + "px") {
        @content;
    }
}
/*-------A-TAG STATES-------*/

@mixin states {
    &:hover, &:active, &:focus {
        @content;
    }
}

/*-------THEMES-------*/

@mixin theme($theme) {
    $result: '';
    @each $item in & {
        $result: $result $theme $item ',';
    }
    @at-root #{$result} {
        @content;
    }
}